import React from 'react'
import Form from '../components/form/form1'
import "./main.css"
const MainPage = () => {
    return (
        <section className='main-page'>
            <ul className='ul-main'>
                <div className='main-div'>
                    <div className='box-div'><img src="./imagenes/Ed-1.jpg" alt="" className='image-obras' /></div>
                </div>
                {/* <h2 className='contacto'>Contacto</h2> */}

            </ul>
        </section>
    )
}

export default MainPage